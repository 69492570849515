@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

/*
page
*/

.page-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 370px;
  position: relative;
  overflow: hidden;

  @include mixin.tabletP {
    height: 210px;
  }

  &__top {
    background-color: rgba(17, 167, 48, .85);
    margin: 0;
    padding: 20px 50px;
    width: 100%;
    max-width: 670px;
    position: relative;
    z-index: 3;

    @include mixin.tabletP {
      padding: 20px;
      width: calc(100% - 70px);
      max-width: 100%;
    }
  }

  &__ttl {
    /* padding-top: 85px; */
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: calc(28px + 14 * (100vw - 428px) / 1492);
    height: 200px;
    position: relative;

    @include mixin.tabletP {
      align-items: flex-start;
      height: 100px;
    }
  }

  &__bottom {
    height: 320px;
    width: calc(100vw - 100px);
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;

    @include mixin.tabletP {
      height: 180px;
      width: 100%;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      transform-origin: left center;
    }

    &:before {
      background: url(#{mixin.set_common_path('pattern_dots.svg')}) repeat center center;
      background-size: 6px 6px;
      z-index: 2;
    }

    &::after {
      background-color: rgba(30, 30, 30, .2);
      mix-blend-mode: multiply;
      transition: transform .3s cubic-bezier(.16,.8,.4,1),opacity .4s;
      /* transform: scaleX(.4);
      opacity: 0; */
      z-index: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      /* opacity: 0; */
      transition: opacity .5s .3s;
    }
  }
}

.page-sec-inner {
  padding: 50px 0 70px;
}
