@charset "UTF-8";
/* 余白 8の倍数 */
/*
page
*/
.page-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 370px;
  position: relative;
  overflow: hidden;
}
@media not all and (min-width: 768px) {
  .page-header {
    height: 210px;
  }
}
.page-header__top {
  background-color: rgba(17, 167, 48, 0.85);
  margin: 0;
  padding: 20px 50px;
  width: 100%;
  max-width: 670px;
  position: relative;
  z-index: 3;
}
@media not all and (min-width: 768px) {
  .page-header__top {
    padding: 20px;
    width: calc(100% - 70px);
    max-width: 100%;
  }
}
.page-header__ttl {
  /* padding-top: 85px; */
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: calc(28px + 14 * (100vw - 428px) / 1492);
  height: 200px;
  position: relative;
}
@media not all and (min-width: 768px) {
  .page-header__ttl {
    align-items: flex-start;
    height: 100px;
  }
}
.page-header__bottom {
  height: 320px;
  width: calc(100vw - 100px);
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
}
@media not all and (min-width: 768px) {
  .page-header__bottom {
    height: 180px;
    width: 100%;
  }
}
.page-header__bottom::before, .page-header__bottom::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform-origin: left center;
}
.page-header__bottom:before {
  background: url(../img/common/pattern_dots.svg) repeat center center;
  background-size: 6px 6px;
  z-index: 2;
}
.page-header__bottom::after {
  background-color: rgba(30, 30, 30, 0.2);
  mix-blend-mode: multiply;
  transition: transform 0.3s cubic-bezier(0.16, 0.8, 0.4, 1), opacity 0.4s;
  /* transform: scaleX(.4);
  opacity: 0; */
  z-index: 1;
}
.page-header__bottom img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* opacity: 0; */
  transition: opacity 0.5s 0.3s;
}

.page-sec-inner {
  padding: 50px 0 70px;
}